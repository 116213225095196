/* Container needed to position the overlay. Adjust the width as needed */
.container {
    position: relative;
    width: 50%;
  }
  
  /* Make the image to responsive */
  .image {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
  
  /* The overlay effect (full height and width) - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: .3s ease;
  }
  
  /* When you mouse over the container, the overlay text will "zoom" in display */
  .container:hover .overlay {
    transform: scale(1);
  }
  
  /* Some text inside the overlay, which is positioned in the middle vertically and horizontally */
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }